<template>
  <div class="mb-3">
    <CRow>
        <loading-overlay :active="apiStateFormLoading" :is-full-page="true" loader="bars" />
      <CCol sm="12" class="d-flex justify-content-end py-2">
        <CButton
          square
          color="add"
          class="d-flex align-items-center"
          @click="activarModal()"
          v-c-tooltip="{
            content: $t('label.model'),
            placement: 'top-end',
          }"
        >
          <CIcon name="cil-playlist-add" />
          <span class="ml-1">
            {{$t('label.nuevo')}}
          </span>
        </CButton>
      </CCol>
    </CRow>
    <dataTableExtended
        class="align-center-row-datatable"
        hover
        sorter
        small
        column-filter
        :table-filter="tableText.tableFilterText"
        :items-per-page-select="tableText.itemsPerPageText"
        :items="formatedItems"
        :fields="fields"
        :noItemsView="tableText.noItemsViewText"
        :items-per-page="5"
        pagination
        :loading="apiStateLoading"                            
    >
        <template #loading>
            <loading/>
            </template>
        <template #BrandRoute="{ index,item }">
            <td class="center-cell">
                <img :src="srcImage(item.BrandRoute)" :alt="item.BrandId+index"    style="height: 32px !important; width: 32px !important;" class="img-thumbnail icon" @error="setAltImg" />
            </td>
        </template>
        <template #ModelRoute="{ index,item }">
            <td class="center-cell">
                <img :src="srcImage(item.ModelRoute)" :alt="item.ModelId+index"  style="height: 32px !important; width: 32px !important;"  class="img-thumbnail icon" @error="setAltImg" />
            </td>
        </template>
        <template #Status="{item}">
            <td class="center-cell">
                <CBadge v-if="item.status" color="success">
                    {{$t('status.'+item.Status)}}
                </CBadge>
                <CBadge v-if="!item.status" color="danger">
                    {{$t('status.'+item.Status)}}
                </CBadge>
            </td>
        </template>
        <template #acciones="{item, index}">
            <td class="center-cell">
                <CButton
                    color="edit"
                    size="sm"
                    class="mr-1"
                    v-c-tooltip="{
                    content: $t('label.edit')+' '+$t('label.model'),
                    placement: 'top-end'
                    }"
                    @click="updateModal(item, index)"
                >
                    <CIcon name="pencil"/>
                </CButton>
            </td>
        </template>
    </dataTableExtended>

    <modal-model
        @set-modal-model-list="getModel"
    />

  </div>
</template>
<script>
    import ENUM from '@/_store/enum';
    import { mapState } from 'vuex';
    import { tableTextTranslatedHelpers } from '@/_helpers/funciones';
    import ModalModel from './modal-model.vue';

    //data
    function data() {
        return {
            srcError:'/img/errorimage.jpg',
            activePage: 1,
            optionsListFiltro:[{value:"ALL",label:"TODOS"},{value:"ACTIVO",label:"ACTIVOS"},{value:"INACTIVO",label:"INACTIVOS"}],
            filtroSelected:"ALL",
        }
    }

    //mounted
    function getModel() {
        this.$store.dispatch('marcamodelo/getMarcalist', { filtro : 'ALL', type : 1});
        this.$store.commit('marcamodelo/mutationModalModel', false);
    }
    //computed
    function apiStateLoading() {
        let carga = false;
        if(this.apiState === ENUM.LOADING || this.apiState === ENUM.INIT){
            carga = !carga;
        }
        return carga;
    }
    function apiStateFormLoading() {
        let carga = false;
        if(this.apiStateForm === ENUM.LOADING){
            carga = !carga;
        }
        return carga;
    }
    function computedItems () {
        return this.$store.getters["marcamodelo/myDataModelotable"];
    }
    function formatedItems() {
        const filtro = this.filtroSelected;
        return this.computedItems.filter(item => {
            if(filtro === "ALL"){
                return item
            }else if(filtro === "ACTIVO"){
                return item.status === true
            }else if(filtro === "INACTIVO"){
                return item.status === false
            }
            
        })
    }

    function fields(){
        return [
            { key: 'Nro', label: '#',filter :false,_style: 'width: 1%; ' },
            { key: 'BrandName',label: this.$t('label.brand'),_classes:"text-left", _style:'width:25%' },
            { key: 'BrandRoute',label: '', _style:'width:100px;',sorter: false,filter: false  },
            { key: 'ModelName',label: this.$t('label.model'),_classes:"text-left", _style:'width:25%' },
            { key: 'ModelRoute',label: '', _style:'width:100px',sorter: false,filter: false },
            { key: 'UltimoUsuario', label: this.$t('label.user'), _classes:"text-left",_style: 'width: 10%;'},
            { key: 'FormatedDate', label: this.$t('label.date'),_classes:"center-cell text-center", _style: 'width: 10%;text-align:center;'},
            { key: 'Status', label: this.$t('label.status'), _style:'width:10%; text-align:center;'},
            { key: 'acciones', label: '', _style: 'width:50px; text-align:center;', sorter: false, filter: false}
        ]
    }

    function tableText(){
        return tableTextTranslatedHelpers(this);
    }

    //methods
    function activarModal(){
        this.$store.commit('marcamodelo/mutationModalModel', true);
    }

    function updateModal(item,index){
        this.$store.state.marcamodelo.ModelRoute = item.ModelRoute;
        this.$store.state.marcamodelo.idModelo = item.ModelId;
        this.$store.commit('marcamodelo/mutationModalModel', true);
    }

    function setRefresh(){
        this.$store.dispatch('marcamodelo/getMarcaModelolist');
    }
    function srcImage(icon){
        if(icon === null || icon === '' || icon === undefined){
            return this.srcError;
        }else{
            return icon;
        }
    }
    function setAltImg(event){
        event.target.src = this.srcError;
    }
    
    //watch
    function apiState(newQuestion,OldQuestion){
        if(newQuestion === ENUM.ERROR){
            if(this.apiStateForm !== ENUM.ERROR){
                this.$notify({
                    group: 'container',
                    type: 'error',
                    ignoreDuplicates:false,
                    title: 'ERROR',
                    text: (this.messageError === '' ? 'Se ha producido un error inesperado' :  this.messageError)
                });
                this.$store.state.marcamodelo.messageError = '';
            }
        }
    }
    function apiStateForm(newQuestion,OldQuestion){
        switch (newQuestion) {
            case ENUM.ERROR:
                this.$notify({
                    group: 'container',
                    type: 'error',
                    ignoreDuplicates:false,
                    title: 'ERROR',
                    text: (this.messageError === '' ? 'Se ha producido un error inesperado' : this.messageError)
                });
                this.$store.state.marcamodelo.messageError = '';
                break;
            case ENUM.LOADED:
                this.$notify({
                    group: 'container',
                    type: 'success',
                    ignoreDuplicates:false,
                    title: '¡Éxito!',
                    text: (this.messageError === '' ? 'Su proceso ha finalizado con éxito' : this.messageError)
                });
                this.$store.state.marcamodelo.messageError = '';
                break;
            default:
                break;
        }
    }

    export default{
        name: 'model-list',
        data,
        components: {
            ModalModel,
            
        },
        methods: {
            getModel,
            updateModal,
            activarModal,
            setRefresh,
            srcImage,
            setAltImg
        },
        computed: {
            computedItems,
            formatedItems,
            apiStateLoading,
            apiStateFormLoading,
            fields,
            tableText,
            ...mapState({
                tabIndex: state => state.marcamodelo.tabIndex,
                apiState: state => state.marcamodelo.apiState,
                messageError: state => state.marcamodelo.messageError,
                apiStateForm: state => state.marcamodelo.apiStateForm,
            })
        },
        watch:{
            tabIndex: function (newValue) {
                if (newValue==1) {
                    this.getModel();
                }
            },
            apiState,
            apiStateForm
        }
    }
</script>
<style lang="scss">

    .icon{
        background-color: null; 
        border: 0px;
    }
    .center-cell {
        text-align: center;
    }
    .table-index {
        table {
            td {
            vertical-align: middle !important;
            }
        }
    }
</style>