var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-3"},[_c('CRow',[_c('loading-overlay',{attrs:{"active":_vm.apiStateFormLoading,"is-full-page":true,"loader":"bars"}}),_c('CCol',{staticClass:"d-flex justify-content-end py-2",attrs:{"sm":"12"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
          content: _vm.$t('label.model'),
          placement: 'top-end',
        }),expression:"{\n          content: $t('label.model'),\n          placement: 'top-end',\n        }"}],staticClass:"d-flex align-items-center",attrs:{"square":"","color":"add"},on:{"click":function($event){return _vm.activarModal()}}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_c('span',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(_vm.$t('label.nuevo'))+" ")])],1)],1)],1),_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"hover":"","sorter":"","small":"","column-filter":"","table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"items":_vm.formatedItems,"fields":_vm.fields,"noItemsView":_vm.tableText.noItemsViewText,"items-per-page":5,"pagination":"","loading":_vm.apiStateLoading},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('loading')]},proxy:true},{key:"BrandRoute",fn:function({ index,item }){return [_c('td',{staticClass:"center-cell"},[_c('img',{staticClass:"img-thumbnail icon",staticStyle:{"height":"32px !important","width":"32px !important"},attrs:{"src":_vm.srcImage(item.BrandRoute),"alt":item.BrandId+index},on:{"error":_vm.setAltImg}})])]}},{key:"ModelRoute",fn:function({ index,item }){return [_c('td',{staticClass:"center-cell"},[_c('img',{staticClass:"img-thumbnail icon",staticStyle:{"height":"32px !important","width":"32px !important"},attrs:{"src":_vm.srcImage(item.ModelRoute),"alt":item.ModelId+index},on:{"error":_vm.setAltImg}})])]}},{key:"Status",fn:function({item}){return [_c('td',{staticClass:"center-cell"},[(item.status)?_c('CBadge',{attrs:{"color":"success"}},[_vm._v(" "+_vm._s(_vm.$t('status.'+item.Status))+" ")]):_vm._e(),(!item.status)?_c('CBadge',{attrs:{"color":"danger"}},[_vm._v(" "+_vm._s(_vm.$t('status.'+item.Status))+" ")]):_vm._e()],1)]}},{key:"acciones",fn:function({item, index}){return [_c('td',{staticClass:"center-cell"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                  content: _vm.$t('label.edit')+' '+_vm.$t('label.model'),
                  placement: 'top-end'
                  }),expression:"{\n                  content: $t('label.edit')+' '+$t('label.model'),\n                  placement: 'top-end'\n                  }"}],staticClass:"mr-1",attrs:{"color":"edit","size":"sm"},on:{"click":function($event){return _vm.updateModal(item, index)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1)],1)]}}])}),_c('modal-model',{on:{"set-modal-model-list":_vm.getModel}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }